@font-face {
    font-family: 'Inter';
    src: url('/public/fontes/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.titulo-criarAnuncio {
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 10px;
    margin-top: 0;
    text-align: left;
}

.radio-label{

    font-size: 120%;
    font-family: 'Inter';
    padding-bottom: 3px;
}

.radio-iconON {
    font-size: 24px;
    margin-right: 8px;
    color: #008192;
  }

.radio-iconOFF {
    font-size: 24px;
    margin-right: 8px;
}