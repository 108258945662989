@font-face {
    font-family: 'Epilogue-Bold';
    src: url('/public/fontes/Epilogue-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    src: url('/public/fontes/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.container-footer{

    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8%;
    margin-bottom: 3%;
}

.footer{

    display: grid;
    grid-template-columns: 16% 16% 16% 16% 16%;
    width: 50%;
    gap: 5%;
}

.coluna{

    display: flex;
    flex-direction: column;
    gap: 10%;
}

.titulo{

    font-family: 'Epilogue-Bold';
}

.texto{

    font-family: 'Inter';
}

@media (max-width: 800px) {
    .texto{

        font-family: 'Inter';
        font-size: 50%;
    }

    .footer{

        display: grid;
        grid-template-columns: 16% 16% 16% 16% 16%;
        width: 90%;
        gap: 5%;
    }
}