@font-face {
    font-family: 'Inter';
    src: url('/public/fontes/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.barra{

    display: flex;
    flex-direction: column;
    position: absolute;
    height: 100vh;
    width: 14%;
}

.barra ul{

    flex-grow: 1;
    list-style-type: none;
    padding: 0;
    padding-left: 15%;
    padding-top: 3%;
    margin: 0;
}

.barra ul li{

    text-decoration: none;
    text-align: left;
    margin-top: 8%;
    font-family: 'Inter';
    font-size: 100%;
    color: #565e6c;
}

.barra ul li:hover{
    color:black;
    font-size: 110%;
    transition: font-size 0.1s ease;
}

.barra ul li.active{
    color:#4069e5;
    font-size: 115%;
    font-weight: bold;
}

.icon {

    margin-right: 2%;
    font-size: 125%;
    margin-bottom: -4px;
}

.subitem {

    margin-left: 7%;
}