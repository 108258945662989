@font-face {
    font-family: 'InterNegrito';
    src: url('/public/fontes/Inter-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    src: url('/public/fontes/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.navbar {

    display: grid;
    grid-template-columns: 7% 20% 38% 30% 5%;
    align-items: center;
    height: 80px;
    color: #fdfdfd;
    background-color: #002567;
}

.container-logo{

    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.botoes{

    display: flex;
    gap: 10%;
    align-items: center;
    justify-content: flex-end;
    font-family: 'Inter';
}

.imagem {

    height: 65px;
}

.texto-logo{

    font-weight: bold;
    font-size: 150%;
    font-family: 'InterNegrito';
}

@media (max-width: 800px) {
    .imagem {

        height: 30px;
    }

    .texto-logo{

        font-weight: bold;
        font-size: 100%;
        font-family: 'InterNegrito';
    }

    .botoes{

        display: flex;
        gap: 10%;
        align-items: center;
        justify-content: flex-end;
        font-family: 'Inter';
        font-size: 50%;
    }

    .navbar {

        display: grid;
        grid-template-columns: 7% 20% 38% 30% 5%;
        align-items: center;
        height: 8%;
        color: #fdfdfd;
        background-color: #002567;
    }
}