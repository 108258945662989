@font-face {
    font-family: 'Epilogue';
    src: url('/public/fontes/Epilogue-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('/public/fontes/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.conteudo-minha-loja{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 50%;
    background: rgba(0, 0, 0, 0.5); /* Optional: to add a dark overlay */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;
    text-align: center;
    margin-bottom: 1%;

}

.search-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 40%;
    padding: 15px;
    border: none;
    border-radius: 35px;
    color: #323842;
    background-color: #ffffff;
    font-size: 150%;
    outline: none;
}

.search-input{

    border: 0;
    outline: none;
    font-size: 100%;
    width: 100%;
}

.botao-search-icon-minhaloja{

    border:0;
    outline: none;
    cursor: pointer;
}

.search-icon-minhaloja{

    margin-right: 2%;
    margin-left: 1%;
    font-size: 160%;
    padding-top: 0.5%;
}

.input-busca-minhaloja {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    margin-right: 10px;
    width: 300px;
    justify-self: left;
}

.grid-anuncios-minhaloja{

    display: grid;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 2%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 50% 50%;
    height: 80%;
    gap:2%;
}

.titulo-minhaloja{

    font-size: 200%;
    font-family: 'Epilogue';
}

.pagination-minhaloja {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.pagination-minhaloja button {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.pagination-minhaloja button.active {
    background-color: #007bff;
    color: white;
}

.imagem-anuncio-minhaloja{

    width: 100%;
    height: 60%;
    border-radius: 8px;
}

.texto-anuncio-minhaloja{

    font-family: 'Inter';
    font-size: 120%;
    font-weight: bold;
}