@font-face {
    font-family: 'Inter';
    src: url('/public/fontes/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.titulo-meusanuncios {
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 10px;
    text-align: left;
    margin-top: 0;
}

.filtros {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.busca {
    margin-top: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.input-busca {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    margin-right: 10px;
}

.botao-busca {
    background-color: #bababab9;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

.botao-busca:hover{

    background-color: #7e7e7eb9;
}

.botao-busca:active{

    transform: scale(0.95);
}

.botao-criar-anuncio {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-left: auto;
}

.botao-criar-anuncio:hover {
    background-color: #0056b3;
}

.botao-criar-anuncio:active{

    transform: scale(0.95);
}

.tabela-anuncios-meus-anuncios {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    background-color: #d0d4db;
}

.tabela-anuncios-meus-anuncios th, .tabela-anuncios-meus-anuncios td {
    border: 1px solid #e7e6e6;
    border-bottom: #000000;
    padding: 8px;
    text-align: center;
}

.tabela-anuncios-meus-anuncios th {
    background-color: #0f2770;
    color: white;
    padding-top: 12px;
    padding-bottom: 12px;
}

.tabela-anuncios-meus-anuncios td {
    padding: 5px;
    text-align: center; /* Centraliza o conteúdo da célula */
}

.thumbnail-meus-anuncios {
    width: auto; /* Define um tamanho fixo para a thumbnail */
    height: auto;
    min-height: 122px;
    min-width: 200px;
    max-width: 200px;
    max-height: 122px;
    border-radius: 3px;
    object-fit: fill; /* Ajusta a imagem para cobrir a célula */
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.pagination button {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.pagination button.active {
    background-color: #007bff;
    color: white;
}

.botao-ver-anuncio-meusanuncios {
    background-color: #9095a0;
    color: white;
    padding: 10px 40px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-left: auto;
    text-decoration: none;
}

.botao-ver-anuncio-meusanuncios:hover {
    background-color: #6d7077;
}

.botao-copiarLink-meusanuncios {
    background-color: #00bdd6;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-left: auto;
    text-decoration: none;
}

.botao-copiarLink-meusanuncios:hover {
    background-color: rgb(3, 90, 172);
}

.texto-tabela-meusanuncios{

    font-family: 'Inter';
    font-size: 110%;
}

.busca-container-meusanuncios {
    display: flex;
    align-items: center;
    position: relative; /* Permite o posicionamento absoluto do ícone */
    flex: 1; /* Faz o container ocupar o espaço restante */
}

.botao-limpar-meusanuncios {
    position: absolute; /* Permite o posicionamento dentro do container */
    right: 20px; /* Alinha o ícone à direita do campo de entrada */
    cursor: pointer;
}

