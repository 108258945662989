@font-face {
    font-family: 'InterNegrito';
    src: url('/public/fontes/Inter-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    src: url('/public/fontes/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.navbar-pub {

    display: grid;
    grid-template-columns: 40% 20%;
    gap:40%;
    padding-left: 10%;
    padding-right: 10%;
    height: 8%;
    color: #fdfdfd;
    background-color: #002567;
}

.container-logo-pub{

    display: flex;
    align-items: center;
    gap: 2%;
    height: 100%;
    justify-content: flex-start;
}

.contato-pub{

    display: flex;
    gap: 3%;
    align-items: center;
    justify-content: flex-end;
    font-size: 150%;
    font-family: 'Inter';
}

.imagem-pub {

    height: 65px;
}

.texto-logo-pub{

    font-weight: bold;
    font-size: 150%;
    font-family: 'InterNegrito';
}

@media (max-width: 800px) {
    .imagem-pub {

        height: 30px;
    }

    .texto-logo-pub{

        font-weight: bold;
        font-size: 100%;
        font-family: 'InterNegrito';
    }
}