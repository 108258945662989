.titulo-meusanuncios {
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 10px;
    text-align: left;
    margin-top: 0;
}

.filtros {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.busca {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.input-busca {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    margin-right: 10px;
}

.botao-busca {
    background-color: #bababab9;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

.botao-busca:hover{

    background-color: #7e7e7eb9;
}

.botao-busca:active{

    transform: scale(0.95);
}

.botao-ver-anuncio-bids {
    background-color: #00bdd6;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-left: auto;
}

.botao-ver-anuncio-bids:hover {
    background-color: #0056b3;
}

.botao-ver-anuncio-bids:active{

    transform: scale(0.95);
}

.tabela-anuncios {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.tabela-anuncios th, .tabela-anuncios td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.tabela-anuncios th {
    background-color: #0f2770;
    color: white;
    padding-top: 12px;
    padding-bottom: 12px;
}

.tabela-anuncios td {
    padding: 5px;
    text-align: center; /* Centraliza o conteúdo da célula */
}

.thumbnail {
    width: 60px; /* Define um tamanho fixo para a thumbnail */
    height: 60px; /* Define um tamanho fixo para a thumbnail */
    object-fit: fill; /* Ajusta a imagem para cobrir a célula */
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.pagination button {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.pagination button.active {
    background-color: #007bff;
    color: white;
}

.anuncio-respostasAnuncios {

    display: grid;
    grid-template-columns: 15% 40% 25% 20%;
    width: 100%;
    padding: 1%;
    padding-left: 0;
    padding-right: 0;
    align-items: center;
}