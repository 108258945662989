@font-face {
    font-family: 'Epilogue-Bold';
    src: url('/public/fontes/Epilogue-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Epilogue';
    src: url('/public/fontes/Epilogue-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.titulo-meuEstoque {
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 20px;
    margin-top: 0;
    text-align: left;
    font-family: 'Epilogue-Bold';
}

.subtitulo-meuEstoque {
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 50px;
    margin-top: 0;
    text-align: left;
    color: #3f4144;
}

.texto-meuEstoque{

    font-weight: bold;
    font-size: 17px;
    margin: 0;
    text-align: left;
    color: #3f4144;
}

.thumbnail-meuestoque {
    width: auto; /* Define um tamanho fixo para a thumbnail */
    height: auto;
    max-width: 200px;
    max-height: 200px;
    border-radius: 3px;
    object-fit: fill; /* Ajusta a imagem para cobrir a célula */
}

.tabela-anuncios-meuestoque {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    background-color: #d0d4db;
}

.tabela-anuncios-meuestoque th, .tabela-anuncios-meus-anuncios td {
    border: 1px solid #e7e6e6;
    border-bottom: #000000;
    padding: 8px;
    text-align: center;
}

.tabela-anuncios-meuestoque th {
    background-color: #0f2770;
    color: white;
    padding-top: 12px;
    padding-bottom: 12px;
}

.tabela-anuncios-meuestoque td {
    padding: 5px;
    text-align: center; /* Centraliza o conteúdo da célula */
}