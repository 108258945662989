.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  outline: none;
  max-width: 700px; /* Aumentei o tamanho máximo do modal */
  width: 100%;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.banner-cadastro {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner-title {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
}

.banner-description {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
  align-items: center;
}

.form-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-input {
  padding: 15px; /* Aumentar o padding */
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 18px; /* Aumentar o tamanho da fonte */
  width: 100%;
  max-width: 600px; /* Ajuste a largura máxima dos inputs */
  box-sizing: border-box;
}

.password-input-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 600px; /* Ajuste a largura máxima dos inputs */
}

.password-input {
  width: 100%; /* Garante que o input de senha ocupe todo o espaço disponível */
  padding-right: 40px; /* Espaço para o ícone */
  padding: 15px; /* Aumentar o padding */
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 18px; /* Aumentar o tamanho da fonte */
  box-sizing: border-box;
}

.toggle-password {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.register-button {
  background-color: #00bfff;
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 20px; /* Aumentar o tamanho da fonte */
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  max-width: 600px; /* Ajuste a largura máxima do botão */
  text-align: center;
}

.login-prompt {
  margin-top: 15px;
}

.login-link {
  color: #00bfff;
  cursor: pointer;
}
