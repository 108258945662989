@font-face {
    font-family: 'Epilogue-ExtraBold';
    src: url('/public/fontes/Epilogue-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    src: url('/public/fontes/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.banner{

    display: flex;
    width: 100%;
    height: 35%;
    align-items: center;
    background-image: url('/public/banner.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-family: 'Epilogue-ExtraBold';
    font-size: 5vh;
    color: aliceblue;
    text-shadow: 10px 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.textoPrincipal{

    display: grid;
    grid-template-columns: 50% 50%;
    margin: 3%;
    font-family: 'Inter';
}

.descricao{

    display: flex;
    flex-direction: column;
    gap: 3vh;
    padding-left: 24%;
}

.descricaoPrincipal{

    font-size: 6vh;
    text-align: left;
}

.descricaoSecundaria{

    font-size: 3vh;
    text-align: left;
    padding-right: 25%;
}

.dados{

    display: flex;
    align-items: start;
}

.img{

    max-width: 100%;
    height: auto;
}

.container{

    display: flex;
    align-items: center;
    justify-content: center;
}

.features{

    display: grid;
    grid-template-columns: 30% 30% 30%;
    gap: 5%;

}

.titulo{

    font-family: 'Epilogue-Bold';
}

.texto{

    font-family: 'Inter';
}

.imagemFeaturesPagIni{

    width: 100%;
}

@media (max-width: 800px) {
    .banner{

        display: flex;
        width: 100%;
        height: 20%;
        align-items: center;
        background-image: url('/public/banner.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        font-family: 'Epilogue-ExtraBold';
        font-size: 1.3vh;
        color: aliceblue;
        text-shadow: 10px 2px 2px 5px rgba(0, 0, 0, 0.3);
    }

    .descricao{

        display: flex;
        flex-direction: column;
        gap: 3vh;
        padding-left: 15%;
    }

    .descricaoPrincipal{

        font-size: 2vh;
        text-align: left;
    }
    
    .descricaoSecundaria{
    
        font-size: 1vh;
        text-align: left;
        padding-right: 25%;
    }

    .img{

        max-width: 100%;
        height: 100%;
    }

    .titulo{

        font-family: 'Epilogue-Bold';
        font-size: 60%;
    }
    
    .texto{
    
        font-family: 'Inter';
        font-size: 60%;
    }

    .features{

        display: grid;
        grid-template-columns: 30% 30% 30%;
        gap: 3%;
        margin-left: 1%;
        margin-right: 1%;
    
    }
}