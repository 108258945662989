@font-face {
    font-family: 'Inter';
    src: url('/public/fontes/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.titulo-anuncio-detalhes {
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 40px;
    text-align: left;
    margin-top: 0;
}

.descricao-detalhes{

    display: grid;
    min-height: 75vh;
    max-height: 75vh;
    overflow-x: hidden;
    grid-template-columns: 0.7fr 0.1fr 1.3fr 0.05fr 1fr;
      
}

.foto-container-detalhes{

    width: 100%;
    height: 20%;
    text-align: left;
}

.foto-detalhes{

    width: 100%;
    height: auto;
    display: block;
    border-radius: 7px;
}

.tracejado{

    display: flex;
    flex-direction: column;
    text-align: left;
    max-width: 100%;
    min-width: 0;
    max-height: 75vh;
    border: 2.5px solid rgba(122, 122, 122, 0.616); /* Adiciona uma borda preta de 2px */
    box-sizing: border-box; /* Garante que a largura e altura incluam a borda */
    border-radius: 10px;
    border-style: dashed;
    padding: 20px;
    word-wrap: break-word; /* Quebra palavras longas */
    overflow-wrap: break-word;
    overflow-y: auto;
  }

.texto-detalhes-titulo{

    font-family:'Inter';
    font-weight: bolder;
    font-size: 130%;
    margin-bottom: 15px;

}

.texto-icone-detalhes{

    font-family:'Inter';
    font-weight: bolder;
    font-size: 110%;
    padding-top: 2px;

}

.icone-detalhes{

    font-weight: bolder;
    font-size: 130%;
}

.texto-detalhes{

    font-family: 'Inter';
    font-size: 110%;
}

.texto-detalhes-botao{

    font-family: 'Inter';
    font-size: 120%;
}

.flex-detalhes{

    display: flex;
    flex-direction: column;
}

.botao-detalhes{

    background-color: #00bdd6;
    margin-top: 8%;
    color: white;
    width: 50%;
    height: 6%;
    text-decoration: none;
    align-self: center;
    font-family: 'Inter';
    font-size: 105%;
    border-radius: 4px;
    outline: none;
    border: none;
}

.botao-remover-detalhes{

    background-color: #e05858;
    margin-top: 56%;
    color: white;
    width: 50%;
    height: 6%;
    text-decoration: none;
    align-self: center;
    justify-self: flex-end;
    font-family: 'Inter';
    font-size: 105%;
    border-radius: 4px;
    outline: none;
    border: none;
}

.botao-detalhes:hover {
    background-color: #03a1b6;
}

.botao-detalhes:active{

    transform: scale(0.95);
}

.botao-remover-detalhes:hover {
    background-color: #ad4444;
}

.botao-remover-detalhes:active{

    transform: scale(0.95);
}

.container-imagepreview-detalhes{

    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150%;
    border: 2.5px solid rgba(158, 157, 157, 0.616); /* Adiciona uma borda preta de 2px */
    box-sizing: border-box; /* Garante que a largura e altura incluam a borda */
    padding: 0;
    border-radius: 10px;
  }