@font-face {
    font-family: 'Epilogue';
    src: url('/public/fontes/Epilogue-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.botao-azul {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-left: auto;
    text-decoration: none;
    font-family: 'Epilogue';
    font-weight: bold;
}

.botao-azul:hover {
    background-color: #0056b3;
}

.botao-azul:active{

    transform: scale(0.95);
}