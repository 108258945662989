@font-face {
    font-family: 'Epilogue-Regular';
    src: url('/public/fontes/Epilogue-Regular.ttf') format('truetype');
}

.breadcrumb{

    display: flex;
}

.breadcrumb ol{

    list-style-type: none;
    display: flex;
    margin-top: 0.1%;
    margin-bottom: 0;
    padding-left: 7.2%;
    flex-grow: 1;
}

.breadcrumb ol li{

    font-family: 'Epilogue-Regular';
}

.breadcrumb ol li:last-child a{

    text-decoration: none;
    font-size: 90%;
    color: #424955;
    font-weight: bold;
    vertical-align: center;

}

.breadcrumb ol li:last-child .icon-aba{

    color: #ffffff;
    position: relative;
    top:5px;
    font-size: 120%;
    margin-left: 3px;
    margin-right: 3px;
}

.breadcrumb ol li a{

    text-decoration: none;
    font-size: 90%;
    color: #00bdd6;
    font-weight: bold;
    vertical-align: center;
}

.icon-aba{

    color: #424955;
    position: relative;
    top:5px;
    font-size: 120%;
    margin-left: 3px;
    margin-right: 3px;
}