@font-face {
    font-family: 'Inter';
    src: url('/public/fontes/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'InterNegrito';
    src: url('/public/fontes/Inter-Bold.ttf') format('truetype');
}

.titulo-anuncio-venda{

    font-weight: bold;
    font-size: 30px;
    margin-bottom: 20px;
    text-align: left;
    margin-top: 0;
}

.conteudo-venda{
    flex-direction:column;
    display: flex;
    min-height: 100vh;
    margin-left: 7%;
    margin-right: 7%;
    overflow-x: hidden;
    padding: 20px;
  }

.grid-detalhes-venda{

    display: grid;
    grid-template-columns: 1.4fr 0.6fr;
    height: 80vh;
}

.tracejado-venda{

    display: flex;
    flex-direction: column;
    text-align: left;
    max-width: 100%;
    min-width: 0;
    max-height: 75vh;
    border: 2.5px solid rgba(122, 122, 122, 0.616); /* Adiciona uma borda preta de 2px */
    box-sizing: border-box; /* Garante que a largura e altura incluam a borda */
    border-radius: 10px;
    border-style: dashed;
    padding: 20px;
    word-wrap: break-word; /* Quebra palavras longas */
    overflow-wrap: break-word;
    overflow-y: auto;
}

.texto-detalhes-venda{

font-family: 'Inter';
font-size: 110%;
}

.proposta-venda{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15%;
    padding-top: 0;
}

.vitrine-venda{

    display: flex;
    margin-bottom: 3%;
    margin-top: 2%;
    height: 322px;
}

.foto-venda{

    width: 30%;
    height: auto;
    display: block;
    margin-right: 20px;
    border-radius: 3px;
}

.seta-foto-venda{

    align-self: center;
    font-size: 300%;
}

.botao-vitrine-venda{

    border: none;
    margin-right: 20px;
    padding: 0;
    cursor: pointer;
}

.campo-proposta-venda{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

}

.input-campo-venda {
    padding: 10px;
    font-size: 16px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    margin-right: 10px;
}

.texto-input-venda{

    font-family: 'Inter';
    font-size: 90%;
    font-weight: bolder;
    margin-bottom: 5px;
    color: #424955;
}

.texto-valor-venda{

    font-family: 'InterNegrito';
    font-size: 110%;
    margin-bottom: 5px;
    color: #000000;
}

.botao-proposta-venda {
    background-color: #379ae6;
    color: white;
    padding: 10px 45px;
    border: none;
    border-radius: 6px;
    font-size: 15px;
    font-family: 'Inter';
    cursor: pointer;
    margin-top: 8%;
    align-self: center;
}