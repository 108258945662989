.titulo-encerrados {
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 10px;
    text-align: left;
    margin-top: 0;
}

.filtros {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.busca-encerrados {
    display: flex;
    align-items: center;
    width: 100%;
}

.circulo {
    width: 60px;
    height: 60px;
    background-color: #3498db;
    border-radius: 50%;
    justify-content: center;
    align-content: center;
}

.circulo-icone{

    font-size: 180%;
    color:white
}

.circulo-texto{

    font-size: 180%;
    font-weight: bolder;
    color: #1443df;
    margin-left: 1%;
}

.input-busca {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    margin-right: 10px;
    width: 300px;
}

.botao-busca {
    background-color: #bababab9;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

.botao-busca:hover{

    background-color: #7e7e7eb9;
}

.botao-busca:active{

    transform: scale(0.95);
}

.botao-criar-anuncio {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-left: auto;
}

.botao-criar-anuncio:hover {
    background-color: #0056b3;
}

.botao-criar-anuncio:active{

    transform: scale(0.95);
}

.tabela-anuncios {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.tabela-anuncios th, .tabela-anuncios td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.tabela-anuncios th {
    background-color: #0f2770;
    color: white;
    padding-top: 12px;
    padding-bottom: 12px;
}

.tabela-anuncios td {
    padding: 5px;
    text-align: center; /* Centraliza o conteúdo da célula */
}

.thumbnail {
    width: 60px; /* Define um tamanho fixo para a thumbnail */
    height: 60px; /* Define um tamanho fixo para a thumbnail */
    object-fit: fill; /* Ajusta a imagem para cobrir a célula */
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.pagination button {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.pagination button.active {
    background-color: #007bff;
    color: white;
}

.botao-status-encerrados{
    display: inline-flex;
    background-color: #a3e7f0;
    color: #5da9b3;
    border: none;
    border-radius: 8px;
    width: 91px;
    height: 36px;
    align-items: center;
    justify-content: center;
}
