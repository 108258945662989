.image-preview{

    display:grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: repeat(2, 50%);
    gap: 2px;
    background-color: #c3c3c3;
    border-radius: 20px;
    min-height: 98%;
}

.preview-image{

    height: 100%;
    width: 100%;
}

.preview-image-borda-superior-esquerda{

    height: 100%;
    width: 100%;
    border-radius: 10px 0 0 0;
}

.preview-image-borda-superior-direita{

    height: 100%;
    width: 100%;
    border-radius: 0 10px 0 0;
}

.preview-image-borda-inferior-esquerda{

    height: 100%;
    width: 100%;
    border-radius: 0 0 0 10px;
}

.preview-image-borda-inferior-direita{

    height: 100%;
    width: 100%;
    border-radius: 0 0 10px 0;
}