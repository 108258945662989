
form{
    display:flex;
    flex-direction:column;
    align-items:center;
}

.form-group-adLivre{

    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 3%;
}

.form-group-adLivre-foto{

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48%;
  height: 100%;
  border: 2.5px solid rgba(158, 157, 157, 0.616); /* Adiciona uma borda preta de 2px */
  box-sizing: border-box; /* Garante que a largura e altura incluam a borda */
  padding: 10px;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.container-form-group{

    display: inline-flex;
    align-items: center;
    width:50%;
}
.container-form-group-foto{

  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width:50%;
}

.form-input-adlivre {
    padding: 15px; /* Aumentar o padding */
    border: 1px solid #33333371;
    border-radius: 5px;
    font-size: 18px; /* Aumentar o tamanho da fonte */
    width: 100%;
    box-sizing: border-box;
  }

  .form-input-adlivre::placeholder{

    color: #bcc1ca;
    
    
  }

  .form-input-descricao-adlivre {
    padding: 15px; /* Aumentar o padding */
    border: 1px solid #33333371;
    border-radius: 5px;
    font-size: 18px; /* Aumentar o tamanho da fonte */
    width: 100%;
    box-sizing: border-box;
    position: relative;
  }

  .form-input-descricao-adlivre::placeholder{

    color: #bcc1ca;
    position: absolute;
    top: 45%;
    
    
  }

.form-group-adLivre label{

    font-weight: bold;
    color: #424955;
    align-self: flex-start;
    margin-bottom: 10px;
}

.addTable-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 15px 30px;
    border-radius: 5px;
    font-size: 20px; /* Aumentar o tamanho da fonte */
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
    max-width: 600px; /* Ajuste a largura máxima do botão */
    text-align: center;
  }

.addTable-button:hover{

    background-color: #0056b3;
}