.radio-group {
    display: flex;
    justify-content: center;
  }
  
  .radio-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 12px;
  }
  
  .radio-container input {
    display: none;
  }