.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
}

/* Esconder o input */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* Slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #bfbfbf;
    transition: .4s;
    border-radius: 25px;
}

/* Bolinha do slider */
.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

/* Quando o input está checked */
input:checked + .slider {
    background-color: #00c4d7;
}

input:checked + .slider:before {
    transform: translateX(25px);
}
