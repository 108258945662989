@font-face {
    font-family: 'InterNegrito';
    src: url('/public/fontes/Inter-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    src: url('/public/fontes/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.politica{

    text-align: justify;
    font-family: 'Inter';
    font-size: 110%;
    font-weight: bold;


}

.titulo-politica{

    font-family: 'InterNegrito';
    color: blue;
    font-size: 210%;
}

.container-politica{

    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 25%;
}