@font-face {
    font-family: 'Epilogue-Bold';
    src: url('/public/fontes/Epilogue-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Epilogue';
    src: url('/public/fontes/Epilogue-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.dados-meuEstoque{

    display: flex;
    flex-direction: row;
    height: 100%;
}

.campos-meuEstoque{

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
}

.inserirFoto-meuEstoque{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 2.5px solid rgba(158, 157, 157, 0.616); /* Adiciona uma borda preta de 2px */
    box-sizing: border-box; /* Garante que a largura e altura incluam a borda */
    padding: 10px;
  }

.texto-dados-meuEstoque{

    text-align: left;
    font-weight: bold;
    font-family: 'Epilogue';
    margin-top:0;
    margin-bottom: 5px;
}

.input-dados-meuEstoque{

    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 5%;
}

.caixaInput-dados-meuEstoque {
    padding: 10px; /* Aumentar o padding */
    border: 1px solid #33333371;
    border-radius: 5px;
    font-size: 18px; /* Aumentar o tamanho da fonte */
    width: 100%;
    box-sizing: border-box;
  }

  .caixaInput-dados-meuEstoque::placeholder{

    color: #bcc1ca;
  }

.botao-dados-meuEstoque {
    background-color: #00bdd6;
    color: white;
    padding: 10px 40px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    align-self: flex-end;
    margin-bottom: 21px;
}

.botao-dados-meuEstoque:hover {
    background-color: rgb(3, 90, 172);
}

.logo-formMeuEstoque{

    width: 80%;
    height: auto;
    display: block;
    margin-left: 10px;
    margin-right: 10px;
}
